
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        



















































































































.separator {
  margin-top: 40px;
}

.search-bar {
  padding-top: 40px;
  padding-bottom: 40px;

  background-color: $gray-200;
  h1 {
    text-align: center;
  }
}
.head {
  margin: $text-lg 20%;
  @media (max-width: $screen-width-sm) {
    margin: $text-lg 10%;
  }

  text-align: center;

  p {
    margin-top: $text-md;
    font-weight: 700;
    font-size: $text-md;
    color: $gray-500;
  }
}
.intro {
  max-width: $screen-width-md;
  margin: auto;
  font-size: $text-sm;
  color: $gray-800;
  padding-bottom: 40px;
  @media (max-width: $screen-width-sm) {
    margin: 0 5%;
  }
}
