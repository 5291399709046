@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@import url(https://use.typekit.net/ejy7pnr.css);
@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.profile-square[data-v-20cde13d] {
  width: 100%;
  color: #f3594c;
  text-align: center;
}
.profile-square-line[data-v-20cde13d] {
    border: 1px solid #f3594c;
    width: 60%;
}
.icon[data-v-20cde13d] {
  width: 40%;
  height: 40%;
}
p span[data-v-20cde13d] {
  display: block;
}
.profile-square-key[data-v-20cde13d] {
  font-weight: bold;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.tab[data-v-6b872c7a] {
  background-color: #fff;
  padding-bottom: 1px;
}
.tab-content[data-v-6b872c7a] {
    max-width: 768px;
    margin: 0 auto;
}
@media (max-width: 576px) {
.tab-content[data-v-6b872c7a] {
        width: 100vw;
        padding: 19px 0;
}
}
.tab-bar[data-v-6b872c7a] {
    padding: 60px 0 30px 0;
    color: #fff;
    background-color: #d8d6cd;
}
.tab-bar ol[data-v-6b872c7a] {
      max-width: 576px;
      margin: 20px auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      list-style: none;
      padding: 0 10px;
}
@media (max-width: 576px) {
.tab-bar ol[data-v-6b872c7a] {
          display: block;
}
}
.tab-bar ol li[data-v-6b872c7a] {
        -webkit-box-flex: 1;
            -ms-flex: 1 1 33%;
                flex: 1 1 33%;
        counter-increment: myTabCounter;
        text-align: left;
        padding-left: 1em;
        border-left: 4px solid #fff;
        cursor: pointer;
}
@media (max-width: 576px) {
.tab-bar ol li[data-v-6b872c7a] {
            margin-bottom: 1em;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
}
.tab-bar ol li div[data-v-6b872c7a] {
              margin-left: 0.5em;
}
}
.tab-bar ol li div[data-v-6b872c7a] {
          color: #414042;
          font-size: 19px;
}
.tab-bar ol li.active[data-v-6b872c7a] {
          border-color: #f3594c;
}
.tab-bar ol li.active[data-v-6b872c7a]:before,
          .tab-bar ol li.active div[data-v-6b872c7a] {
            color: #f3594c;
            font-weight: bold;
}
.tab-bar ol li[data-v-6b872c7a]:before {
          content: counter(myTabCounter) ".";
          display: block;
          font-size: 100px;
          line-height: 1;
}
.tab-competence-icon[data-v-6b872c7a] {
    margin: 0 auto;
    margin-bottom: 26px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #f3594c;
    font-size: 26px;
    font-weight: bold;
    line-height: 1;
}
.tab-competence-icon img[data-v-6b872c7a] {
      height: 3em;
      width: 3em;
}
.tab-competence-icon-expertise img[data-v-6b872c7a] {
      margin-bottom: -20px;
}
.tab-title[data-v-6b872c7a] {
    font-weight: 700;
    font-size: 36px;
    text-align: center;
}
.tab-content-video[data-v-6b872c7a] {
    background-color: #d8d6cd;
    position: relative;
}
.tab-content-video[data-v-6b872c7a]:before {
      content: "";
      display: block;
      width: 100%;
      height: 100px;
      background-color: #edeae5;
      position: absolute;
      bottom: 0px;
}
.tab-content-video-content[data-v-6b872c7a] {
      position: relative;
      max-width: 576px;
      margin: 0 auto;
}
.tab .tab-competence[data-v-6b872c7a] {
    max-width: 576px;
    margin: 0 auto;
}
@media (max-width: 576px) {
.tab .tab-competence[data-v-6b872c7a] {
        width: 100vw;
        padding: 19px !important;
}
}
.tab-content-quote[data-v-6b872c7a] {
    background-color: #edeae5;
}
.tab-content-quote .tab-competence[data-v-6b872c7a] {
      padding: 36px 0;
      text-align: center;
      color: #f3594c;
      font-weight: 300;
      font-size: 26px;
}
.tab-content-profile[data-v-6b872c7a], .tab-content-education[data-v-6b872c7a] {
    background-color: #edeae5;
    padding-bottom: 1px;
}
.tab-content-profile .tab-competence[data-v-6b872c7a], .tab-content-education .tab-competence[data-v-6b872c7a] {
      background-color: #fff;
      margin-bottom: 36px;
      padding: 36px 72px;
}
@media (max-width: 576px) {
.tab-content-profile .tab-competence[data-v-6b872c7a], .tab-content-education .tab-competence[data-v-6b872c7a] {
          padding: 19px !important;
}
}
.tab-content-presentations[data-v-6b872c7a], .tab-content-videos[data-v-6b872c7a] {
    background-color: #edeae5;
    padding-bottom: 1px;
}
.tab-content-presentations .tab-competence[data-v-6b872c7a], .tab-content-videos .tab-competence[data-v-6b872c7a] {
      background-color: #fff;
      margin-bottom: 36px;
      padding: 36px 72px;
}
@media (max-width: 576px) {
.tab-content-presentations .tab-competence[data-v-6b872c7a], .tab-content-videos .tab-competence[data-v-6b872c7a] {
          padding: 19px 0 !important;
}
}
.tab-content-references[data-v-6b872c7a] {
    background-color: #edeae5;
    padding-bottom: 1px;
}
.tab-content-references .tab-competence[data-v-6b872c7a] {
      background-color: #fff;
      margin-bottom: 36px;
      padding: 36px 72px;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.booking[data-v-fbbf7be6] {
  background-color: #edeae5;
  padding: 40px 0px;
  color: #aca79e;
}
.status[data-v-fbbf7be6] {
  width: 992px;
  max-width: 100vw;
  margin: auto;
  background-color: #aca79e;
  color: white;
  padding: 10px 44px;
}
@media (max-width: 576px) {
.status[data-v-fbbf7be6] {
      width: 100vw;
}
}
.status .label[data-v-fbbf7be6] {
    font-weight: 600;
}
.booking-form[data-v-fbbf7be6] {
  width: 992px;
  max-width: 100vw;
  margin: auto;
  padding: 44px;
  background-color: #fff;
}
@media (max-width: 576px) {
.booking-form[data-v-fbbf7be6] {
      width: 100vw;
}
}
.intro[data-v-fbbf7be6] {
  color: #f3594c;
  font-weight: 500;
  font-size: 26px;
  padding-bottom: 26px;
}
button[data-v-fbbf7be6] {
  display: inline-block;
  font-size: 19px;
  line-height: 1.2;
  font-weight: inherit;
  color: #000;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 0;
  padding: 10px 44px 12px 44px;
  border: 1px solid #f3594c;
  text-decoration: none;
  background: #fff;
  color: #f3594c;
  -webkit-appearance: none;
  -moz-appearance: none;
}
button[data-v-fbbf7be6]:hover {
    color: #000;
    text-decoration: none;
}
button[data-v-fbbf7be6]:focus, button.focus[data-v-fbbf7be6] {
    outline: 0;
    color: #f3594c;
    background-color: #fff;
    border-color: #fff;
}
button[data-v-fbbf7be6]:hover, button[data-v-fbbf7be6]:focus {
    background: #f3594c;
    color: #fff;
    border-color: #fff;
}
button[data-v-fbbf7be6]:disabled, button[data-v-fbbf7be6]:disabled:hover {
    color: #f3594c;
    border-color: #f3594c;
    background: #fff;
    cursor: not-allowed;
}
h2[data-v-fbbf7be6] {
  font-size: 44px;
  text-align: center;
  margin-bottom: 44px;
}
.circle[data-v-fbbf7be6] {
  display: inline-block;
  border-radius: 50%;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  border: 1px solid #f3594c;
  color: #f3594c;
  width: 36px;
  height: 36px;
  text-align: center;
  margin-right: 10px;
}
h3[data-v-fbbf7be6] {
  font-weight: 400;
  color: #aca79e;
  font-size: 26px;
}
.section[data-v-fbbf7be6] {
  margin-top: 20px;
}
.section h3[data-v-fbbf7be6] {
    margin-bottom: 10px;
}
.section label[data-v-fbbf7be6] {
    display: none;
    text-align: right;
    margin-right: 10px;
}
@media (max-width: 576px) {
.section label[data-v-fbbf7be6] {
        width: auto;
        text-align: left;
        padding-top: 10px;
        padding-bottom: 2px;
}
}
.section .termsAndConditions[data-v-fbbf7be6],
  .section .subscribeNewsletter[data-v-fbbf7be6] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 14px 0;
}
.section .termsAndConditions input[data-v-fbbf7be6],
    .section .subscribeNewsletter input[data-v-fbbf7be6] {
      width: auto;
      margin-bottom: 0;
}
.section label[for="termsAndConditions"][data-v-fbbf7be6],
  .section label[for="subscribeNewsletter"][data-v-fbbf7be6] {
    display: block;
    line-height: 1;
    padding: 0;
    padding-left: 26px;
    margin-bottom: 0;
}
.section label[for="termsAndConditions"][data-v-fbbf7be6] {
    color: #000;
}
.section textarea[data-v-fbbf7be6],
  .section input[data-v-fbbf7be6] {
    font-size: 19px;
    padding: 0 10px;
}
.section textarea[data-v-fbbf7be6]:focus,
    .section input[data-v-fbbf7be6]:focus {
      outline: none;
}
.section input[type="text"][data-v-fbbf7be6] {
    width: 100%;
    min-height: 50px;
    margin-bottom: 19px;
}
.section input[type="text"][data-v-fbbf7be6]::-webkit-input-placeholder {
      color: #aca79e;
}
.section input[type="text"][data-v-fbbf7be6]::-moz-placeholder {
      color: #aca79e;
}
.section input[type="text"][data-v-fbbf7be6]:-ms-input-placeholder {
      color: #aca79e;
}
.section input[type="text"][data-v-fbbf7be6]::-ms-input-placeholder {
      color: #aca79e;
}
.section input[type="text"][data-v-fbbf7be6]::placeholder {
      color: #aca79e;
}
.invalid[data-v-fbbf7be6] {
  outline: 1px solid #f3594c;
}
.question[data-v-fbbf7be6] {
  margin-top: 16px;
  width: 100%;
  height: 200px;
  resize: none;
  border-color: #edeae5;
}
.error[data-v-fbbf7be6] {
  margin-top: -14px;
  color: #f3594c;
}
.hidden[data-v-fbbf7be6] {
  display: none !important;
}
.button-row[data-v-fbbf7be6] {
  text-align: right;
  padding-top: 36px;
}
.answer[data-v-fbbf7be6] {
  color: #414042;
}
.multiselect[data-v-fbbf7be6] {
  margin: 0;
  margin-bottom: 19px;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.mentor {
  position: relative;
}
.mentor-header {
    width: 100%;
    height: 800px;
    padding-top: 100px;
    background-color: #495161;
    background-size: cover;
}
@media (max-width: 992px) {
.mentor-header {
        height: unset;
        padding-top: 0;
        background-image: unset !important;
}
}
.mentor-quote {
    max-width: 576px;
    margin: auto;
    padding: 19px 0;
    color: #aca79e;
    font-weight: 300;
    font-size: 26px;
}
.mentor-image {
    padding-top: 36px;
    padding-bottom: 14px;
    width: 160px;
    margin: auto;
    background-color: #fff;
    z-index: 9999;
}
.mentor-image img {
      display: block;
      border-radius: 50%;
      background-clip: padding-box;
      /* stops bg color from leaking outside the border: */
      background-color: #d8d6cd;
      width: 160px;
      height: 160px;
      -o-object-fit: cover;
         object-fit: cover;
      -o-object-position: top center;
         object-position: top center;
}
.mentor-image a:hover img {
      -webkit-filter: none;
              filter: none;
}
.mentor-body {
    max-width: 992px !important;
    padding: 0 132px;
    background-color: #fff;
    margin: 0 auto;
    text-align: center;
}
@media (max-width: 576px) {
.mentor-body {
        max-width: 100vw;
        width: 100vw;
        padding: 0 14px;
}
}
.mentor-name {
    padding: 20px 0;
}
.mentor-name h2 {
      font-weight: 400;
      font-size: 17px;
}
.mentor-squares {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    max-width: 576px;
    margin: auto;
}
@media (max-width: 576px) {
.mentor-squares {
        padding: 0;
        margin: 0;
        width: 90vw;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}
}
.mentor-square {
    width: 20%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
@media (max-width: 576px) {
.mentor-square {
        width: 30vw;
}
}
.mentor-icon {
    color: #f3594c;
    width: 60px;
}
.mentor hr {
    border: 5px solid #f3594c;
    width: 210px;
    margin-top: 10px;
}
.mentor-button {
    background-color: #f3594c;
    margin-top: 20px;
    padding: 0.5em;
    text-align: center;
}
.mentor-button a {
      color: white;
      text-decoration: none;
}
.mentor-button a:hover {
        text-decoration: underline;
}
.mentor-link-arrow {
    margin-left: auto;
    margin-right: 0px;
}
.mentor .hide {
    display: none;
}
.mentor .booking {
    margin-top: 40px;
}
.cta {
  text-align: center;
  padding: 36px 0;
  color: #fff;
}
.cta-button {
    display: inline-block;
    font-size: 19px;
    line-height: 1.2;
    font-weight: inherit;
    color: #000;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: transparent;
    border: 1px solid #000;
    border-radius: 0;
    padding: 10px 44px 12px 44px;
    background-color: #f3594c;
    color: #fff;
    border: 1px solid #fff;
}
.cta-button:hover {
      color: #000;
      text-decoration: none;
}
.cta-button:focus, .cta-button.focus {
      outline: 0;
      color: #f3594c;
      background-color: #fff;
      border-color: #fff;
}
.cta-button:hover {
      background-color: #fff;
      color: #f3594c !important;
      border: 1px solid #f3594c;
      text-decoration: none;
}
.cta .icon {
    margin-left: -6px;
    margin-bottom: -18px;
    margin-top: -18px;
    display: inline-block;
    width: 36px;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.mentor-tile[data-v-4272af4d] {
  height: 100%;
  width: 100%;
}
.mentor-tile a[data-v-4272af4d] {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: 320px;
    color: inherit;
    text-decoration: none;
}
@media (max-width: 576px) {
.mentor-tile a[data-v-4272af4d] {
        display: block;
}
.mentor-tile a > *[data-v-4272af4d] {
          max-width: 100vw;
}
}
.mentor-tile a[data-v-4272af4d]:hover {
      -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.mentor-tile a:hover .mentor-tile-header-image img[data-v-4272af4d] {
        opacity: 1;
}
.mentor-tile a:hover .mentor-tile-header-button[data-v-4272af4d] {
        opacity: 1;
        left: 0;
        right: 0;
        line-height: 2em;
}
.mentor-tile-header[data-v-4272af4d] {
    position: relative;
}
@media (max-width: 576px) {
.mentor-tile-header[data-v-4272af4d] {
        height: 50vh;
        margin-bottom: 9.5px;
}
}
.mentor-tile-header-image[data-v-4272af4d] {
      width: 100%;
      height: 100%;
      margin-bottom: 14px;
}
.mentor-tile-header-image img[data-v-4272af4d] {
        -o-object-fit: cover;
           object-fit: cover;
        -o-object-position: center top;
           object-position: center top;
        width: 100%;
        height: 100%;
        background-color: #aca79e;
}
@media (min-width: 768px) {
.mentor-tile-header-image img[data-v-4272af4d] {
            opacity: 0.5;
            -webkit-transition: opacity 0.15s ease-in-out;
            transition: opacity 0.15s ease-in-out;
}
}
.mentor-tile-header-button[data-v-4272af4d] {
      display: inline-block;
      font-size: 19px;
      line-height: 1.2;
      font-weight: inherit;
      color: #000;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      background-color: transparent;
      border: 1px solid #000;
      border-radius: 0;
      padding: 10px 44px 12px 44px;
      line-height: 1.5em;
      padding: 10px;
      padding-right: 36px;
      text-align: left;
      background-color: #f3594c;
      color: #fff;
      border-color: #f3594c;
      position: absolute;
      font-size: 14px;
      right: 14px;
      left: 14px;
      bottom: 14px;
}
.mentor-tile-header-button[data-v-4272af4d]:hover {
        color: #000;
        text-decoration: none;
}
.mentor-tile-header-button[data-v-4272af4d]:focus, .mentor-tile-header-button.focus[data-v-4272af4d] {
        outline: 0;
        color: #f3594c;
        background-color: #fff;
        border-color: #fff;
}
@media (max-width: 768px) {
.mentor-tile-header-button[data-v-4272af4d] {
          right: 0;
          left: 0;
          line-height: 2em;
}
}
@media (min-width: 768px) {
.mentor-tile-header-button[data-v-4272af4d] {
          opacity: 0.5;
          -webkit-transition: left, right, line-height 1.5s ease-in-out;
          transition: left, right, line-height 1.5s ease-in-out;
          -webkit-transition: opacity 0.15s ease-in-out;
          transition: opacity 0.15s ease-in-out;
}
}
.mentor-tile-header-button[data-v-4272af4d]:hover {
        color: #fff;
        font-weight: bold;
}
.mentor-tile-header-button[data-v-4272af4d]:after {
        content: "";
        display: inline-block;
        background-image: url("/img/icons/arrow_button.svg");
        background-repeat: no-repeat;
        width: 1em;
        height: 1em;
        position: absolute;
        right: 1em;
        bottom: 1em;
}
.mentor-tile-body[data-v-4272af4d] {
    height: 100%;
    padding: 0 14px;
    background-color: #fff;
    overflow: hidden;
}
.mentor-tile-body-tags[data-v-4272af4d] {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      overflow: hidden;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      margin-top: 5px;
}
.mentor-tile-body-tag[data-v-4272af4d] {
      border: 1px solid #f3594c;
      color: #f3594c;
      padding: 9.5px 26px;
      margin-right: 7px;
      line-height: 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      font-weight: bold;
      font-size: 14px;
}
@media (max-width: 768px) {
.mentor-tile-body-tag[data-v-4272af4d] {
          margin-bottom: 7px;
}
}
.mentor-tile-body-name[data-v-4272af4d] {
      font-weight: 700;
      font-size: 36px;
      color: #f3594c;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0;
}
.mentor-tile-body-title[data-v-4272af4d] {
      color: #414042;
      padding-bottom: 14px;
      font-size: 19px;
      font-weight: 500;
}
.mentor-tile-body-icons[data-v-4272af4d] {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      color: #f3594c;
      margin-left: -10px;
      margin-top: -6px;
}
.mentor-tile-body-icons *[data-v-4272af4d] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        text-align: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
}
.mentor-tile-body-icons a[data-v-4272af4d] {
        color: #f3594c;
}
.mentor-tile-body-icons a[data-v-4272af4d]:hover {
          text-decoration: none;
          color: #888894;
}
.mentor-tile-body-icons a:hover .icon[data-v-4272af4d] {
            color: #f3594c;
            background-color: #888894;
}
.mentor-tile-body-icon[data-v-4272af4d] {
      width: 72px;
}
.mentor-tile-body-icon-image[data-v-4272af4d] {
        border-radius: 50%;
        background-clip: padding-box;
        /* stops bg color from leaking outside the border: */
        background-color: #edeae5;
        width: 36px;
        height: 36px;
        margin-bottom: 4px;
}
.mentor-tile-body-icon-image .phone[data-v-4272af4d] {
          padding: 4px 0 6px 0;
}
.mentor-tile-body-icon-value[data-v-4272af4d], .mentor-tile-body-icon-label[data-v-4272af4d] {
        font-size: 12px;
        line-height: 1.3;
}
.mentor-tile-body-icon-value[data-v-4272af4d] {
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
}
.mentor-tile-profile[data-v-4272af4d] {
    padding: 26px 0;
    text-align: justify;
    padding-top: 8px;
    font-size: 19px;
    line-height: 1.3rem;
    color: #414042;
}
@media (max-width: 576px) {
.mentor-tile-profile[data-v-4272af4d] {
        padding-bottom: 0;
}
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.mentor-grid[data-v-7436fa8a] {
  display: block;
  padding: 40px 0;
  max-width: 992px;
  margin: 0 auto;
}
.mentor-grid-card[data-v-7436fa8a] {
  margin: 8px 0;
  margin-top: 10px;
  margin-bottom: 10px;
}
@media (max-width: 576px) {
.mentor-grid-card[data-v-7436fa8a] {
      width: 100vw;
      margin-bottom: 40px;
}
}
.mentor-grid-rule[data-v-7436fa8a] {
  margin: 14px;
  border: 0.5px solid #d8d6cd;
}
@media (max-width: 576px) {
.mentor-grid-rule[data-v-7436fa8a] {
      border: 0.5px solid #fff;
}
}
.placeholder[data-v-7436fa8a] {
  content: " ";
  background-color: yellow;
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.separator[data-v-c3b0381c] {
  margin-top: 40px;
}
.search-bar[data-v-c3b0381c] {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #d8d6cd;
}
.search-bar h1[data-v-c3b0381c] {
    text-align: center;
}
.head[data-v-c3b0381c] {
  margin: 36px 20%;
  text-align: center;
}
@media (max-width: 576px) {
.head[data-v-c3b0381c] {
      margin: 36px 10%;
}
}
.head p[data-v-c3b0381c] {
    margin-top: 26px;
    font-weight: 700;
    font-size: 26px;
    color: #aca79e;
}
.intro[data-v-c3b0381c] {
  max-width: 768px;
  margin: auto;
  font-size: 19px;
  color: #3b3d42;
  padding-bottom: 40px;
}
@media (max-width: 576px) {
.intro[data-v-c3b0381c] {
      margin: 0 5%;
}
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
button[data-v-2b3d6769] {
  display: inline-block;
  font-size: 19px;
  line-height: 1.2;
  font-weight: inherit;
  color: #000;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 0;
  padding: 10px 44px 12px 44px;
  border: 1px solid #f3594c;
  text-decoration: none;
  background: #f3594c;
  color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
}
button[data-v-2b3d6769]:hover {
    color: #000;
    text-decoration: none;
}
button[data-v-2b3d6769]:focus, button.focus[data-v-2b3d6769] {
    outline: 0;
    color: #f3594c;
    background-color: #fff;
    border-color: #fff;
}
@media (max-width: 576px) {
button[data-v-2b3d6769] {
      font-size: 14px;
      width: 100%;
      text-align: center;
      padding: 10px 14px 12px 14px;
}
}
button[data-v-2b3d6769]:disabled, button[data-v-2b3d6769]:disabled:hover {
    color: #f3594c;
    border-color: #f3594c;
    background: #fff;
    cursor: not-allowed;
}
button[data-v-2b3d6769]:hover, button[data-v-2b3d6769]:focus {
    background: #fff;
    color: #f3594c;
    border-color: #fff;
}
.section[data-v-2b3d6769] {
  margin-top: 20px;
}
.section h3[data-v-2b3d6769] {
    margin-bottom: 10px;
}
.section label[data-v-2b3d6769] {
    display: none;
    width: 260px;
    text-align: right;
    margin-right: 10px;
}
@media (max-width: 576px) {
.section label[data-v-2b3d6769] {
        width: auto;
        text-align: left;
        padding-top: 10px;
        padding-bottom: 2px;
}
}
.section textarea[data-v-2b3d6769],
  .section input[data-v-2b3d6769] {
    font-size: 19px;
    padding: 0px 10px;
    border: none;
}
@media (max-width: 576px) {
.section textarea[data-v-2b3d6769],
      .section input[data-v-2b3d6769] {
        font-size: 14px;
}
}
.section textarea[data-v-2b3d6769]:focus,
    .section input[data-v-2b3d6769]:focus {
      outline: none;
}
.section input[data-v-2b3d6769] {
    width: 100%;
    min-height: 50px;
}
.section input[data-v-2b3d6769]::-webkit-input-placeholder {
      color: #aca79e;
}
.section input[data-v-2b3d6769]::-moz-placeholder {
      color: #aca79e;
}
.section input[data-v-2b3d6769]:-ms-input-placeholder {
      color: #aca79e;
}
.section input[data-v-2b3d6769]::-ms-input-placeholder {
      color: #aca79e;
}
.section input[data-v-2b3d6769]::placeholder {
      color: #aca79e;
}
.button-row[data-v-2b3d6769] {
  margin-top: 26px;
}
.invalid[data-v-2b3d6769] {
  outline: 1px solid #f3594c;
}
.question[data-v-2b3d6769] {
  margin-top: 16px;
  width: 100%;
  height: 200px;
  resize: none;
  border-color: #edeae5;
}
.error[data-v-2b3d6769] {
  color: #f3594c;
}
.hidden[data-v-2b3d6769] {
  display: none !important;
}
.subscribeNewsletter[data-v-2b3d6769] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-top: 14px;
  padding-left: 4px;
  margin-bottom: 19px;
}
.subscribeNewsletter input[data-v-2b3d6769] {
    width: auto;
    margin-bottom: 0;
}
label[for="subscribeNewsletter"][data-v-2b3d6769] {
  display: block;
  line-height: 1;
  padding: 0;
  padding-left: 26px;
  margin-bottom: 0;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.mentor-registration-head[data-v-11c6f28a] {
  margin: 36px 20%;
  text-align: center;
}
@media (max-width: 576px) {
.mentor-registration-head[data-v-11c6f28a] {
      margin: 36px 10%;
}
}
.mentor-registration-head h1[data-v-11c6f28a] {
    line-height: 1.4;
    background-color: inherit;
}
.mentor-registration-head p[data-v-11c6f28a] {
    margin-top: 26px;
    font-weight: 700;
    font-size: 26px;
    color: #aca79e;
}
.mentor-registration-intro[data-v-11c6f28a] {
  max-width: 768px;
  margin: auto;
  font-size: 19px;
  color: #3b3d42;
  padding-bottom: 40px;
}
@media (max-width: 576px) {
.mentor-registration-intro[data-v-11c6f28a] {
      margin: 0 5%;
}
}
.mentor-registration-body[data-v-11c6f28a] {
  width: 100%;
  background-color: #d8d6cd;
  padding: 44px 0;
}
.mentor-registration-body-confirm[data-v-11c6f28a], .mentor-registration-body-error[data-v-11c6f28a] {
    background-color: #fff;
    padding: 19px;
    margin: auto 0;
}
.mentor-registration-body-form[data-v-11c6f28a] {
    max-width: 992px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 14px;
}
@media (max-width: 768px) {
.mentor-registration-body-form[data-v-11c6f28a] {
        display: block;
}
}
.mentor-registration-body-form-text[data-v-11c6f28a] {
      margin: 36px 19px;
}
.mentor-registration-body-form-text h1[data-v-11c6f28a] {
        color: #fff;
}
.mentor-registration-body-form-text p[data-v-11c6f28a] {
        font-weight: 500;
        font-size: 19px;
        color: #414042;
}

@font-face {
  font-family: "Quarto Bold";
  font-display: auto;
  src: url("/fonts/quarto_bold.woff") format("woff"), url("/fonts/quarto_bold.ttf") format("truetype");
}
.question-registration-head[data-v-6c8f76c2] {
  margin: 36px 20%;
  text-align: center;
}
@media (max-width: 576px) {
.question-registration-head[data-v-6c8f76c2] {
      margin: 36px 10%;
}
}
.question-registration-head p[data-v-6c8f76c2] {
    margin-top: 26px;
    font-weight: 700;
    font-size: 26px;
    color: #aca79e;
}
.question-registration-intro[data-v-6c8f76c2] {
  max-width: 768px;
  margin: auto;
  font-size: 19px;
  color: #3b3d42;
  padding-bottom: 40px;
}
@media (max-width: 576px) {
.question-registration-intro[data-v-6c8f76c2] {
      margin: 0 5%;
}
}
.question-registration-body[data-v-6c8f76c2] {
  width: 100%;
  background-color: #d8d6cd;
  padding: 44px 0;
}
.question-registration-body-confirm[data-v-6c8f76c2], .question-registration-body-error[data-v-6c8f76c2] {
    background-color: #fff;
    padding: 19px;
    margin: auto 0;
}
.question-registration-body-form[data-v-6c8f76c2] {
    max-width: 992px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 14px;
}
@media (max-width: 768px) {
.question-registration-body-form[data-v-6c8f76c2] {
        display: block;
}
}
.question-registration-body-form-text[data-v-6c8f76c2] {
      margin: 44px 19px;
}
.question-registration-body-form-text h1[data-v-6c8f76c2] {
        color: #fff;
}
.question-registration-body-form-text p[data-v-6c8f76c2] {
        color: #414042;
        font-size: 19px;
}
button[data-v-6c8f76c2] {
  display: inline-block;
  font-size: 19px;
  line-height: 1.2;
  font-weight: inherit;
  color: #000;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid #000;
  border-radius: 0;
  padding: 10px 44px 12px 44px;
  border: 1px solid #f3594c;
  text-decoration: none;
  background: #f3594c;
  color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
}
button[data-v-6c8f76c2]:hover {
    color: #000;
    text-decoration: none;
}
button[data-v-6c8f76c2]:focus, button.focus[data-v-6c8f76c2] {
    outline: 0;
    color: #f3594c;
    background-color: #fff;
    border-color: #fff;
}
button[data-v-6c8f76c2]:disabled, button[data-v-6c8f76c2]:disabled:hover {
    color: #f3594c;
    border-color: #c8c9ba;
    background: #fff;
    cursor: not-allowed;
}
button[data-v-6c8f76c2]:hover, button[data-v-6c8f76c2]:focus {
    background: #fff;
    color: #f3594c;
    border-color: #fff;
}
.section[data-v-6c8f76c2] {
  margin-top: 20px;
}
.section h3[data-v-6c8f76c2] {
    margin-bottom: 10px;
}
.section label[data-v-6c8f76c2] {
    display: none;
    width: 260px;
    text-align: right;
    margin-right: 10px;
}
@media (max-width: 576px) {
.section label[data-v-6c8f76c2] {
        width: auto;
        text-align: left;
        padding-top: 10px;
        padding-bottom: 2px;
}
}
.section textarea[data-v-6c8f76c2],
  .section input[data-v-6c8f76c2] {
    font-size: 19px;
    padding: 0px 10px;
}
.section textarea[data-v-6c8f76c2]:focus,
    .section input[data-v-6c8f76c2]:focus {
      outline: none;
}
.section input[data-v-6c8f76c2] {
    width: 100%;
    min-height: 50px;
}
.section input[data-v-6c8f76c2]::-webkit-input-placeholder {
      color: #aca79e;
}
.section input[data-v-6c8f76c2]::-moz-placeholder {
      color: #aca79e;
}
.section input[data-v-6c8f76c2]:-ms-input-placeholder {
      color: #aca79e;
}
.section input[data-v-6c8f76c2]::-ms-input-placeholder {
      color: #aca79e;
}
.section input[data-v-6c8f76c2]::placeholder {
      color: #aca79e;
}
.button-row[data-v-6c8f76c2] {
  margin-top: 19px;
  text-align: right;
}
.invalid[data-v-6c8f76c2] {
  outline: 1px solid #f3594c;
}
.question[data-v-6c8f76c2] {
  margin-top: 16px;
  width: 100%;
  height: 200px;
  resize: none;
  border-color: #edeae5;
}
.error[data-v-6c8f76c2] {
  color: #f3594c;
}
.hidden[data-v-6c8f76c2] {
  display: none !important;
}


/*# sourceMappingURL=mentor.f10c7784.css.map*/