
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        








































































































































































































































.question-registration {
  &-head {
    margin: $text-lg 20%;
    @media (max-width: $screen-width-sm) {
      margin: $text-lg 10%;
    }

    text-align: center;

    p {
      margin-top: $text-md;
      font-weight: 700;
      font-size: $text-md;
      color: $gray-500;
    }
  }

  &-intro {
    max-width: $screen-width-md;
    margin: auto;
    font-size: $text-sm;
    color: $gray-800;
    padding-bottom: 40px;
    @media (max-width: $screen-width-sm) {
      margin: 0 5%;
    }
  }

  &-body {
    width: 100%;
    background-color: $gray-200;
    padding: $text-xl 0;
    &-confirm,
    &-error {
      background-color: $white;
      padding: $text-sm;
      margin: auto 0;
    }
    &-form {
      max-width: $screen-width-lg;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: $text-xs;

      @media (max-width: $screen-width-md) {
        display: block;
      }
      &-text {
        margin: $text-xl $text-sm;
        h1 {
          color: $white;
        }
        p {
          color: $gray-700;
          font-size: $text-sm;
        }
      }
    }
  }
}

button {
  @include button;
  border: 1px solid $accent-color;
  text-decoration: none;
  background: $accent-color;
  color: $white;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:disabled,
  &:disabled:hover {
    color: $accent-color;
    border-color: $gray-400;
    background: $white;
    cursor: not-allowed;
  }

  &:hover,
  &:focus {
    background: $white;
    color: $accent-color;
    border-color: $white;
  }
}

.section {
  margin-top: 20px;

  h3 {
    margin-bottom: 10px;
  }

  label {
    display: none;
    width: 260px;
    text-align: right;
    margin-right: 10px;
    @media (max-width: $screen-width-sm) {
      width: auto;
      text-align: left;
      padding-top: 10px;
      padding-bottom: 2px;
    }
  }

  textarea,
  input {
    font-size: $text-sm;
    padding: 0px 10px;
    &:focus {
      outline: none;
    }
  }

  input {
    width: 100%;
    min-height: 50px;

    &::placeholder {
      color: $gray-500;
    }
  }
}

.button-row {
  margin-top: $text-sm;
  text-align: right;
}

.invalid {
  outline: 1px solid $accent-color;
}

.question {
  margin-top: 16px;
  width: 100%;
  height: 200px;
  resize: none;
  border-color: $gray-100;
}

.error {
  color: $accent-color;
}

.hidden {
  display: none !important;
}
