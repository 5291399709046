
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        





















.profile-square {
  width: 100%;
  color: $accent-color;
  text-align: center;
  &-line {
    border: 1px solid $accent-color;
    width: 60%;
  }
}

.icon {
  width: 40%;
  height: 40%;
}

p span {
  display: block;
}

.profile-square-key {
  font-weight: bold;
}
