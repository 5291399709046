
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        

































































































































.mentor-tile {
  height: 100%;
  width: 100%;

  a {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: 320px;
    @media (max-width: $screen-width-sm) {
      display: block;
      & > * {
        max-width: 100vw;
      }
    }
    color: inherit;
    text-decoration: none;
    &:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      .mentor-tile-header-image img {
        opacity: 1;
      }
      .mentor-tile-header-button {
        opacity: 1;
        left: 0;
        right: 0;
        line-height: 2em;
      }
    }
  }

  &-header {
    position: relative;
    @media (max-width: $screen-width-sm) {
      height: 50vh;
      margin-bottom: $text-sm / 2;
    }
    &-image {
      width: 100%;
      height: 100%;
      margin-bottom: $text-xs;
      & img {
        object-fit: cover;
        object-position: center top;
        width: 100%;
        height: 100%;
        background-color: $gray-500;
        @media (min-width: $screen-width-md) {
          opacity: 0.5;
          transition: opacity 0.15s ease-in-out;
        }
      }
    }
    &-button {
      @include button;
      line-height: 1.5em;
      padding: 10px;
      padding-right: $text-lg;
      text-align: left;
      background-color: $red;
      color: $white;
      border-color: $red;
      position: absolute;
      font-size: $text-xs;
      right: $text-xs;
      left: $text-xs;
      bottom: $text-xs;
      @media (max-width: $screen-width-md) {
        right: 0;
        left: 0;
        line-height: 2em;
      }
      @media (min-width: $screen-width-md) {
        opacity: 0.5;
        transition: left, right, line-height 1.5s ease-in-out;
        transition: opacity 0.15s ease-in-out;
      }

      &:hover {
        color: $white;
        font-weight: bold;
      }
      &:after {
        content: "";
        display: inline-block;
        background-image: url("/img/icons/arrow_button.svg");
        background-repeat: no-repeat;
        width: 1em;
        height: 1em;
        position: absolute;
        right: 1em;
        bottom: 1em;
      }
    }
  }

  &-body {
    height: 100%;
    padding: 0 $text-xs;
    background-color: $white;
    overflow: hidden;
    &-tags {
      width: 100%;
      display: flex;
      justify-content: center;
      overflow: hidden;
      flex-wrap: wrap;
      margin-top: 5px;
    }

    &-tag {
      border: 1px solid $red;
      color: $red;
      padding: $text-sm / 2 $text-md;
      margin-right: $text-xs / 2;
      line-height: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-weight: bold;
      font-size: $text-xs;
      @media (max-width: $screen-width-md) {
        margin-bottom: $text-xs / 2;
      }
    }

    &-name {
      font-weight: 700;
      font-size: $text-lg;
      color: $accent-color;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0;
    }

    &-title {
      color: $gray-700;
      padding-bottom: $text-xs;
      font-size: $text-sm;
      font-weight: 500;
    }

    &-icons {
      width: 100%;
      display: flex;
      flex-direction: row;
      color: $accent-color;
      margin-left: -10px;
      margin-top: -6px;

      & * {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
      }

      a {
        color: $accent-color;
        &:hover {
          text-decoration: none;
          color: $gray-600;
          .icon {
            color: $accent-color;
            background-color: $gray-600;
          }
        }
      }
    }

    &-icon {
      width: 2 * $text-lg;
      &-image {
        @include border-radius(50%);
        background-color: $gray-100;
        width: $text-lg;
        height: $text-lg;
        margin-bottom: 4px;
        .phone {
          padding: 4px 0 6px 0;
        }
      }

      &-value,
      &-label {
        font-size: $text-xs - 2;
        line-height: 1.3;
      }

      &-value {
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &-profile {
    padding: $text-md 0;
    text-align: justify;
    @media (max-width: $screen-width-sm) {
      padding-bottom: 0;
    }
    padding-top: $text-xs - 6;
    font-size: $text-sm;
    line-height: 1.3rem;
    color: $gray-700;
  }
}
