
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        



























































































































































.tab {
  background-color: $white;
  padding-bottom: 1px;

  &-content {
    max-width: $screen-width-md;
    margin: 0 auto;

    @media (max-width: $screen-width-sm) {
      width: 100vw;
      padding: $text-sm 0;
    }
  }

  &-bar {
    padding: 60px 0 30px 0;
    color: $white;
    background-color: $gray-200;

    ol {
      max-width: $screen-width-sm;
      margin: 20px auto;
      display: flex;
      list-style: none;
      padding: 0 10px;

      @media (max-width: $screen-width-sm) {
        display: block;
      }

      li {
        flex: 1 1 33%;
        counter-increment: myTabCounter;
        text-align: left;
        padding-left: 1em;
        border-left: 4px solid $white;
        cursor: pointer;

        @media (max-width: $screen-width-sm) {
          margin-bottom: 1em;
          display: flex;
          flex-direction: row;
          align-items: center;
          div {
            margin-left: 0.5em;
          }
        }

        div {
          color: $gray-700;
          font-size: $text-sm;
        }

        &.active {
          border-color: $red;
          &:before,
          div {
            color: $red;
            font-weight: bold;
          }
        }

        &:before {
          content: counter(myTabCounter) ".";
          display: block;
          font-size: 100px;
          line-height: 1;
        }
      }
    }
  }

  &-competence-icon {
    margin: 0 auto;
    margin-bottom: $text-md;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $red;
    font-size: $text-md;
    font-weight: bold;
    line-height: 1;

    img {
      height: 3em;
      width: 3em;
    }

    &-expertise {
      img {
        margin-bottom: -20px;
      }
    }
  }

  &-title {
    font-weight: 700;
    font-size: $text-lg;
    text-align: center;
  }

  &-content-video {
    background-color: $gray-200;
    position: relative;
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100px;
      background-color: $gray-100;
      position: absolute;
      bottom: 0px;
    }
    &-content {
      position: relative;
      max-width: $screen-width-sm;
      margin: 0 auto;
    }
  }

  .tab-competence {
    max-width: $screen-width-sm;
    margin: 0 auto;
    @media (max-width: $screen-width-sm) {
      width: 100vw;
      padding: $text-sm !important;
    }
  }

  &-content-quote {
    background-color: $gray-100;
    .tab-competence {
      padding: $text-lg 0;
      text-align: center;
      color: $red;
      font-weight: 300;
      font-size: $text-md;
    }
  }
  &-content-profile,
  &-content-education {
    background-color: $gray-100;
    padding-bottom: 1px;
    .tab-competence {
      background-color: $white;
      margin-bottom: $text-lg;
      padding: $text-lg 2 * $text-lg;
      @media (max-width: $screen-width-sm) {
        padding: $text-sm !important;
      }
    }
  }
  &-content-presentations,
  &-content-videos {
    background-color: $gray-100;
    padding-bottom: 1px;
    .tab-competence {
      background-color: $white;
      margin-bottom: $text-lg;
      padding: $text-lg 2 * $text-lg;
      @media (max-width: $screen-width-sm) {
        padding: $text-sm 0 !important;
      }
    }
  }
  &-content-references {
    background-color: $gray-100;
    padding-bottom: 1px;
    .tab-competence {
      background-color: $white;
      margin-bottom: $text-lg;
      padding: $text-lg 2 * $text-lg;
    }
  }
}
