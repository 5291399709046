
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        




















































































.mentor-registration {
  &-head {
    margin: $text-lg 20%;
    @media (max-width: $screen-width-sm) {
      margin: $text-lg 10%;
    }

    text-align: center;

    h1 {
      line-height: 1.4;
      background-color: inherit;
    }

    p {
      margin-top: $text-md;
      font-weight: 700;
      font-size: $text-md;
      color: $gray-500;
    }
  }

  &-intro {
    max-width: $screen-width-md;
    margin: auto;
    font-size: $text-sm;
    color: $gray-800;
    padding-bottom: 40px;
    @media (max-width: $screen-width-sm) {
      margin: 0 5%;
    }
  }

  &-body {
    width: 100%;
    background-color: $gray-200;
    padding: $text-xl 0;

    &-confirm,
    &-error {
      background-color: $white;
      padding: $text-sm;
      margin: auto 0;
    }
    &-form {
      max-width: $screen-width-lg;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: $text-xs;

      @media (max-width: $screen-width-md) {
        display: block;
      }
      &-text {
        margin: $text-lg $text-sm;
        h1 {
          color: $white;
        }
        p {
          font-weight: 500;
          font-size: $text-sm;
          color: $gray-700;
        }
      }
    }
  }
}
