
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        


































































































































































































































































































































































































































































































































































































.booking {
  background-color: $gray-100;
  padding: 40px 0px;
  color: $gray-500;
}

.status {
  width: $screen-width-lg;
  max-width: 100vw;
  @media (max-width: $screen-width-sm) {
    width: 100vw;
  }
  margin: auto;
  background-color: $gray-500;
  color: white;
  padding: 10px $text-xl;
  .label {
    font-weight: 600;
  }
}

.booking-form {
  width: $screen-width-lg;
  max-width: 100vw;
  @media (max-width: $screen-width-sm) {
    width: 100vw;
  }
  margin: auto;
  padding: $text-xl;
  background-color: $white;
}

.intro {
  color: $accent-color;
  font-weight: 500;
  font-size: $text-md;
  padding-bottom: $text-md;
}

button {
  @include button;
  border: 1px solid $accent-color;
  text-decoration: none;
  background: $white;
  color: $accent-color;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover,
  &:focus {
    background: $accent-color;
    color: $white;
    border-color: $white;
  }

  &:disabled,
  &:disabled:hover {
    color: $accent-color;
    border-color: $accent-color;
    background: $white;
    cursor: not-allowed;
  }
}

h2 {
  font-size: $text-xl;
  text-align: center;
  margin-bottom: $text-xl;
}

.circle {
  display: inline-block;
  @include border-radius(50%);
  border: 1px solid $accent-color;
  color: $accent-color;
  width: $text-lg;
  height: $text-lg;
  text-align: center;
  margin-right: 10px;
}

h3 {
  font-weight: 400;
  color: $gray-500;
  font-size: $text-md;
}

.section {
  margin-top: 20px;

  h3 {
    margin-bottom: 10px;
  }

  label {
    display: none;
    text-align: right;
    margin-right: 10px;
    @media (max-width: $screen-width-sm) {
      width: auto;
      text-align: left;
      padding-top: 10px;
      padding-bottom: 2px;
    }
  }

  .termsAndConditions,
  .subscribeNewsletter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: $text-xs 0;
    input {
      width: auto;
      margin-bottom: 0;
    }
  }

  label[for="termsAndConditions"],
  label[for="subscribeNewsletter"] {
    display: block;
    line-height: 1;
    padding: 0;
    padding-left: $text-md;
    margin-bottom: 0;
  }
  label[for="termsAndConditions"] {
    color: $black;
  }

  textarea,
  input {
    font-size: $text-sm;
    padding: 0 10px;
    &:focus {
      outline: none;
    }
  }

  input[type="text"] {
    width: 100%;
    min-height: 50px;
    margin-bottom: $text-sm;

    &::placeholder {
      color: $gray-500;
    }
  }
}

.invalid {
  outline: 1px solid $accent-color;
}

.question {
  margin-top: 16px;
  width: 100%;
  height: 200px;
  resize: none;
  border-color: $gray-100;
}

.error {
  margin-top: -$text-xs;
  color: $accent-color;
}

.hidden {
  display: none !important;
}

.button-row {
  text-align: right;
  padding-top: $text-lg;
}

.answer {
  color: $gray-700;
}

.multiselect {
  margin: 0;
  margin-bottom: $text-sm;
}
