
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        

















































































































































































button {
  @include button;
  border: 1px solid $accent-color;
  text-decoration: none;
  background: $accent-color;
  color: $white;
  -webkit-appearance: none;
  -moz-appearance: none;
  @media (max-width: $screen-width-sm) {
    font-size: $text-xs;
    width: 100%;
    text-align: center;
    padding: 10px $text-xs 12px $text-xs;
  }
  &:disabled,
  &:disabled:hover {
    color: $accent-color;
    border-color: $accent-color;
    background: $white;
    cursor: not-allowed;
  }

  &:hover,
  &:focus {
    background: $white;
    color: $accent-color;
    border-color: $white;
  }
}

.section {
  margin-top: 20px;

  h3 {
    margin-bottom: 10px;
  }

  label {
    display: none;
    width: 260px;
    text-align: right;
    margin-right: 10px;
    @media (max-width: $screen-width-sm) {
      width: auto;
      text-align: left;
      padding-top: 10px;
      padding-bottom: 2px;
    }
  }

  textarea,
  input {
    font-size: $text-sm;
    @media (max-width: $screen-width-sm) {
      font-size: $text-xs;
    }
    padding: 0px 10px;
    &:focus {
      outline: none;
    }
    border: none;
  }

  input {
    width: 100%;
    min-height: 50px;

    &::placeholder {
      color: $gray-500;
    }
  }
}

.button-row {
  margin-top: $text-md;
}

.invalid {
  outline: 1px solid $accent-color;
}

.question {
  margin-top: 16px;
  width: 100%;
  height: 200px;
  resize: none;
  border-color: $gray-100;
}

.error {
  color: $accent-color;
}

.hidden {
  display: none !important;
}

.subscribeNewsletter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: $text-xs;
  padding-left: 4px;
  margin-bottom: $text-sm;
  input {
    width: auto;
    margin-bottom: 0;
  }
}

label[for="subscribeNewsletter"] {
  display: block;
  line-height: 1;
  padding: 0;
  padding-left: $text-md;
  margin-bottom: 0;
}
