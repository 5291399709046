
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        










































































































































































.mentor {
  position: relative;

  &-header {
    width: 100%;
    height: 800px;
    padding-top: 100px;
    background-color: $accent-secondary-color;
    background-size: cover;

    @media (max-width: $screen-width-lg) {
      height: unset;
      padding-top: 0;
      background-image: unset !important;
    }
  }

  &-quote {
    max-width: $screen-width-sm;
    margin: auto;
    padding: $text-sm 0;
    color: $gray-500;
    font-weight: 300;
    font-size: $text-md;
  }

  &-image {
    padding-top: $text-lg;
    padding-bottom: $text-xs;
    width: 160px;
    margin: auto;
    background-color: $white;
    z-index: 9999;
    img {
      display: block;
      @include border-radius(50%);
      background-color: $gray-200;
      width: 160px;
      height: 160px;
      object-fit: cover;
      object-position: top center;
    }
    a:hover {
      img {
        filter: none;
      }
    }
  }

  &-body {
    max-width: $screen-width-lg !important;
    padding: 0 $text-xl * 3;
    background-color: $white;
    margin: 0 auto;
    text-align: center;
    @media (max-width: $screen-width-sm) {
      max-width: 100vw;
      width: 100vw;
      padding: 0 $text-xs;
    }
  }

  &-name {
    padding: 20px 0;
    h2 {
      font-weight: 400;
      font-size: 17px;
    }
  }

  &-squares {
    display: flex;
    justify-content: space-around;
    max-width: $screen-width-sm;
    margin: auto;

    @media (max-width: $screen-width-sm) {
      padding: 0;
      margin: 0;
      width: 90vw;
      flex-wrap: wrap;
    }
  }

  &-square {
    width: 20%;

    @media (max-width: $screen-width-sm) {
      width: 30vw;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-icon {
    color: $accent-color;
    width: 60px;
  }

  hr {
    border: 5px solid $accent-color;
    width: 210px;
    margin-top: 10px;
  }

  &-button {
    background-color: $accent-color;
    margin-top: 20px;
    padding: 0.5em;
    text-align: center;
    & a {
      color: white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-link-arrow {
    margin-left: auto;
    margin-right: 0px;
  }

  .hide {
    display: none;
  }

  .booking {
    margin-top: 40px;
  }
}

.cta {
  text-align: center;
  padding: $text-lg 0;
  color: $white;

  &-button {
    @include button;

    background-color: $accent-color;
    color: $white;
    border: 1px solid $white;

    &:hover {
      background-color: $white;
      color: $accent-color !important;
      border: 1px solid $accent-color;
      text-decoration: none;
    }
  }

  .icon {
    margin-left: -6px;
    margin-bottom: -$text-lg/2;
    margin-top: -$text-lg/2;
    display: inline-block;
    width: $text-lg;
  }
}
