
          @import "@/styles/_fonts.scss";
          @import "@/styles/_variables.scss";
          @import "@/styles/_mixins.scss";
        



































.mentor-grid {
  display: block;
  padding: 40px 0;
  max-width: $screen-width-lg;
  margin: 0 auto;
}

.mentor-grid-card {
  margin: 8px 0;
  margin-top: 10px;
  margin-bottom: 10px;
  @media (max-width: $screen-width-sm) {
    width: 100vw;
    margin-bottom: 40px;
  }
}

.mentor-grid-rule {
  margin: $text-xs;
  @media (max-width: $screen-width-sm) {
    border: 0.5px solid $white;
  }
  border: 0.5px solid $gray-200;
}

.placeholder {
  content: " ";
  background-color: yellow;
  width: 100%;
  height: 100%;
}
